import React, {Component} from 'react';
import {Constants} from "../Constants";
import {Link, withRouter} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Axios from 'axios';
import {addToCart, updateCart} from "../../Library/Actions/cartActions";
import store from "../../Library/Store";
import Shimmer from "../Shimmer";
import {$} from 'react-jquery-plugin'
import DocumentTitler from "../../Library/DocumentTitler";
import Error404 from "../Errors/Error404";

const initState = {
    isLoading: true,
    initQty: 1,
    bundle: {
        variantID: 0,
        variantPrice: 0,
        variantSalePrice: 0,
    },
    product: {
        id: 0,
        title: "",
        code: "",
        category: "",
        price: 0,
        salePrice: 0,
        pages: 0,
        size: "",
        publisher: "",
        inventory: "",
        weight: 0,
        bulkQty: 0,
        bulkPrice: 0,
        author: "",
        translator: "",
        coupon: "",
        description: "",
        image: "",
        imageHD: "",
        sample: "",
        tagging: "",
        edition: "",
        printing: "",
        paper: "",
        binding: "",
        status: 0,
        variantID: 0,
        variantPrice: 0,
        variantSalePrice: 0,
        variants: [
            {
                id: 0,
                name: "",
                desc: "",
                price: 0,
                salePrice: 0
            }
        ]
    }
}

let productID = 0;
let findProduct;
const dispatch = store.dispatch;

class ProductView extends Component {

    constructor(props) {
        super(props);
        this.state = initState;
        productID = props.match.params.id;
    }

    componentDidMount() {
        Axios.get(`${Constants.API_URL}product/${productID}`)
            .then(res => {
                if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                    if (res.data.products.length > 0) {
                        this.setState({isLoading: false, product: res.data.products[0]});
                        this.getCartQty(this.state.product);
                        this.getVariantPrice(this.state.product.id);
                    } else {
                        this.setState({isLoading:false})
                    }
                    setTimeout(() => {
                        window.setup();
                    }, 1000)
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("componentDidUpdate:", this.state.product)
    }

    getCartQty(product) {
        findProduct = store.getState().cart.products.find(itm => itm.id === product.id)
        if (findProduct !== undefined) this.setState({initQty: findProduct.itemQty});
    }

    isInCart(product) {
        findProduct = store.getState().cart.products.find(itm => itm.id === product.id)
        return findProduct !== undefined;
    }

    isSelectedVariant(productID, variantID) {
        findProduct = store.getState().cart.products.find(itm => itm.variantID === variantID && itm.id === productID)
        return findProduct !== undefined;
    }

    getVariantPrice(productID) {
        findProduct = store.getState().cart.products.find(itm => itm.id === productID)
        let vBundle = this.state.bundle;
        if (findProduct !== undefined) {
            vBundle.variantID = findProduct.variantID;
            vBundle.variantPrice = findProduct.variantPrice;
            vBundle.variantSalePrice = findProduct.variantSalePrice;
        } else {
            vBundle.variantID = 0;
            vBundle.variantPrice = 0;
            vBundle.variantSalePrice = 0;
        }
        this.setState({vBundle})
    }

    variantDetails(option) {
        let opTag = $(option.target);
        opTag.next("p").html(opTag.find("option:selected").prop("title"));
        let vBundle = this.state.bundle;
        if (opTag.find("option:selected").attr("data-key") !== undefined) {
            let variant = this.state.product.variants[opTag.find("option:selected").attr("data-key")];
            vBundle.variantID = parseInt(option.target.value);
            vBundle.variantPrice = variant.price;
            vBundle.variantSalePrice = variant.salePrice;
            this.setState({vBundle})
        } else {
            vBundle.variantID = 0;
            vBundle.variantPrice = 0;
            vBundle.variantSalePrice = 0;
            this.setState({vBundle})
        }
        this.isInCart(this.state.product)
    }

    render() {
        return (
            <>
                <DocumentTitler title={this.state.product.title}/>
                <Shimmer isLoading={this.state.isLoading}>
                    <div className="container margin_30">
                        <div className="row">
                            <div className="col-md-6 add_bottom_25">
                                <div className="productImage animate"/>
                            </div>
                            <div className="col-md-6">
                                <div className="productBreadCrumb animate"/>
                                <div className="productTitle mt-2 animate"/>
                                <div className="productSKU mt-2 animate"/>
                                <div className="container">
                                    <div className="row mt-5">
                                        <div className="productQTY mr-5 animate"/>
                                        <div className="productQTYBox animate"/>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="productPrice mr-5 animate"/>
                                        <div className="productButtons animate"/>
                                        <div className="productPrice mr-5 "/>
                                        <div className="productButtons2 mt-3 animate"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="productTitle w35 animate"/>
                                        <div className="productDetails mt-3 animate"/>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="productTitle w35 animate"/>
                                        <div className="productDetails mt-3 animate"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Shimmer>
                {this.state.product.id > 0 ?
                    <main className="d-none">
                        <div className="container margin_30">
                            <div className="row">
                                <div className="col-md-6 add_bottom_25">
                                    <div className="all">
                                        <div className="slider">
                                            <div className=" owl-theme main">
                                                <div style={{
                                                    backgroundImage: `url(${encodeURI(this.state.product.imageHD)})`,
                                                    backgroundSize: "contain",
                                                    backgroundRepeat: "no-repeat"
                                                }} className="item-box"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/products">&nbsp;Products</Link></li>
                                            <li>&nbsp;{this.state.product.title}</li>
                                        </ul>
                                    </div>
                                    <div className="prod_info">
                                        <h1>{this.state.product.title}</h1>
                                        <p><small>SKU: {this.state.product.code}</small></p>
                                        <div className="prod_options">
                                            <div className="row">
                                                <label className="col-xl-5 col-lg-5  col-md-6 col-6"><strong>Quantity</strong></label>
                                                <div className="col-xl-4 col-lg-5 col-md-6 col-6">
                                                    <div className="numbers-row">
                                                        <input type="text" value={this.state.initQty} autoComplete="off"
                                                               onChange={(e) => {
                                                                   this.setState({initQty: e.target.value})
                                                               }}
                                                               className="qty2" name="quantity"/>
                                                        <div className="inc button_inc" onClick={() => {
                                                            this.setState({initQty: $(".qty2").val()})
                                                        }}>+
                                                        </div>
                                                        <div className="dec button_inc" onClick={() => {
                                                            this.setState({initQty: $(".qty2").val()})
                                                        }}>-
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-5 col-md-6">
                                                <div className="price_main">
                                                    {this.state.product.salePrice !== 0 ? <><span
                                                            className="new_price">₹ {this.state.bundle.variantSalePrice !== 0 ? this.state.bundle.variantSalePrice : this.state.product.salePrice}</span> &nbsp;
                                                            <span
                                                                className="old_price">₹ {this.state.bundle.variantPrice !== 0 ? this.state.bundle.variantPrice : this.state.product.price}</span></>
                                                        : <span
                                                            className="new_price">₹ {this.state.bundle.variantPrice !== 0 ? this.state.bundle.variantPrice : this.state.product.price}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {this.state.product.tagging === Constants.PRODUCT_STATES.UnderPublishing || this.state.product.inventory === 0 ?
                                                    <button type="button" className="btn_1"
                                                            data-prod={this.state.product.id}><span className="fa fa-book"/> Book Now
                                                    </button> :
                                                    !this.isInCart(this.state.product) ?
                                                        <div className="btn_add_to_cart">
                                                            <button type="button"
                                                                    onClick={() => dispatch(addToCart(this.state.product, this.state.bundle, $(".qty2").val()))}
                                                                    className="btn_1 detailCartButton">Add to Cart
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="btn_add_to_cart">
                                                            <button type="button"
                                                                    onClick={() => dispatch(updateCart(this.state.product, this.state.bundle, $(".qty2").val()))}
                                                                    className="btn_1 detailCartButton">Update Cart
                                                            </button>
                                                        </div>

                                                }
                                                {this.state.product.sample !== '' ?
                                                    <div className="btn_add_to_cart mt-2">
                                                        <Link to={`/assets/upload/sample_pages/${this.state.product.sample}`}
                                                              target="_top" className="btn_1">
                                                            <span className="fa fa-download"/>Download Sample
                                                        </Link>
                                                    </div> : ""}
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.product.variants.length > 0 ?
                                        <div className="row">
                                            <div className="col-md-12 mt-3">
                                                <strong>Bundles with:</strong>
                                                <select id="variants" className="form-control" onChange={(e) => {
                                                    this.variantDetails(e)
                                                }}>
                                                    <option value="" title="">Select bundle</option>
                                                    {this.state.product.variants.map((variant, key) => {
                                                        return (<option value={variant.id} title={variant.desc}
                                                                        data-key={key}
                                                                        selected={this.isSelectedVariant(this.state.product.id, variant.id)}>{variant.name}</option>);
                                                    })}
                                                </select>
                                                <p className="pt-3 pb-3"/>
                                            </div>
                                        </div> : ""}

                                </div>
                            </div>
                        </div>
                        <div className="tabs_product">
                            <div className="container">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <HashLink id="tab-A" to="#pane-A" className="nav-link active" data-toggle="tab" role="tab">Description</HashLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab_content_wrapper">
                            <div className="container">
                                <div className="tab-content" role="tablist">
                                    <div id="pane-A" className="card tab-pane fade active show" role="tabpanel" aria-labelledby="tab-A">
                                        <div className="card-header" role="tab" id="heading-A">
                                            <h5 className="mb-0">
                                                <HashLink className="expanded" data-toggle="collapse" to="#collapse-A" aria-expanded="false"
                                                          aria-controls="collapse-A">
                                                    Description
                                                </HashLink>
                                            </h5>
                                        </div>
                                        <div id="collapse-A" className="expanded" role="tabpanel" aria-labelledby="heading-A">
                                            <div className="card-body">
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-6">
                                                        <h3>Details</h3>
                                                        <p dangerouslySetInnerHTML={{__html: this.state.product.description}}/>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <h3>Specifications</h3>
                                                        <div className="table-responsive">
                                                            <table className="table table-sm table-striped">
                                                                <tbody>
                                                                <tr>
                                                                    <td><strong>Pages</strong></td>
                                                                    <td>{this.state.product.pages}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Size</strong></td>
                                                                    <td>{this.state.product.size}<em>cm</em></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Weight</strong></td>
                                                                    <td>{this.state.product.weight}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Status</strong></td>
                                                                    <td>
                                                                        {this.state.product.inventory > 0 ? <em style={{color: "#093"}}> Ready Available</em> :
                                                                            <em style={{color: "#F00"}}> Out of Stock</em>}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Edition</strong></td>
                                                                    <td>{this.state.product.edition}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Printing</strong></td>
                                                                    <td>{this.state.product.printing}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Paper</strong></td>
                                                                    <td>{this.state.product.paper}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Binding</strong></td>
                                                                    <td>{this.state.product.binding}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    :
                    !this.state.isLoading && <Error404/>}
            </>
        );
    }
}

export default withRouter(ProductView);