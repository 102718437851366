import React, {Component} from 'react';
import {$} from 'react-jquery-plugin'
import './Shimmer.css';

class Shimmer extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        let isLoading = this.props.isLoading;
        $("main").each(function () {
            if (!isLoading) {
                $(this).removeClass("d-none");
                $(".shimmer").hide();
            } else {
                $(this).addClass("d-none");
                $(".shimmer").show();
            }
        })
    }

    render() {
        return (
            <div className="shimmer">
                {this.props.children}
            </div>
        );
    }
}

export default Shimmer;