import React from 'react';
import {Constants} from "../Constants";
import {Link} from "react-router-dom";

function ProductItem(props) {
    return (
        <div className={`col-6 ${props.itemGrid === 5 ? "col-md-2" : props.itemGrid === 4 ? "col-md-3" : "col-md-3"}`} key={props.key}>
            <div className="grid_item" itemID={props.itemGrid}>
                {props.product.tagging === Constants.PRODUCT_STATES.NEW ? <span className="ribbon new">New</span> : ""}
                {props.product.tagging === Constants.PRODUCT_STATES.UnderPublishing ? <span className="ribbon off">Under Publishing</span> : ""}
                {props.product.tagging === Constants.PRODUCT_STATES.UpComing ? <span className="ribbon off">Up-Coming</span> : ""}
                {props.product.inventory === 0 ? <span className="ribbon hot">Out of Stock</span> : ""}
                <figure>
                    <Link to={`/products/v/${props.product.id}`}>
                        <img className="img-fluid lazy" src={props.product.image} data-src={props.product.imageHD} alt={props.product.title}/>
                    </Link>
                </figure>
                <Link to={`/products/v/${props.product.id}`}>
                    <h3>{props.product.title}</h3>
                </Link>
                <div className="price_box">
                    {props.product.salePrice !== 0 ?
                        <><span className="new_price">₹{props.product.salePrice}</span>&nbsp;
                            <span className="old_price">₹{props.product.price}</span></>
                        : <span className="new_price">₹{props.product.price}</span>
                    }
                </div>
            </div>
        </div>
    );
}

ProductItem.defaultProps = {
    itemGrid: 4
}

export default ProductItem;