import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import Axios from 'axios';
import {Constants} from "./Constants";
import {$} from 'react-jquery-plugin';
import Shimmer from "./Shimmer";
import store from "../Library/Store";
import {addToCart} from "../Library/Actions/cartActions";
import DocumentTitler from "../Library/DocumentTitler";

const initState = {
    isLoading: true,
    banners: [
        {
            title: "Now Order Your Favourite Books Online",
            banner: "/assets/upload/slides/slide_n01.png",
            action: "/main/online_payments",
            actionName: "Shop Now"
        }, {
            title: "Pan India Delivery Service",
            banner: "/assets/upload/slides/slide_n02.png",
            action: "/main/delivery_service",
            actionName: "Shop Now"
        }
    ],
    products: [
        {
            param: "",
            category: "",
            items: {
                count: 0,
                products: {
                    id: 0,
                    title: "",
                    code: "",
                    category: "",
                    price: 0,
                    salePrice: "",
                    pages: 0,
                    size: "",
                    publisher: "",
                    inventory: "",
                    weight: 0,
                    bulkQty: "",
                    bulkPrice: "",
                    author: "",
                    translator: "",
                    coupon: "",
                    description: "",
                    image: "",
                    imageHD: "",
                    sample: "",
                    tagging: "",
                    edition: "",
                    printing: "",
                    paper: "",
                    binding: "",
                    status: 0
                }
            }
        }
    ]

}

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
    }


    loadBanners() {
        $("#carousel-home .owl-carousel").on("initialized.owl.carousel", function () {
            setTimeout(function () {
                $("#carousel-home .owl-carousel .owl-item.active .owl-slide-animated").addClass("is-transitioned");
                $("section").show();
            }, 200);
        });

        const $owlCarousel = $("#carousel-home .owl-carousel").owlCarousel({
            items: 1,
            loop: true,
            nav: false,
            dots: true,
            responsive: {
                0: {
                    dots: false
                },
                767: {
                    dots: false
                },
                768: {
                    dots: true
                }
            }
        });

        $owlCarousel.on("changed.owl.carousel", function (e) {
            $(".owl-slide-animated").removeClass("is-transitioned");
            const $currentOwlItem = $(".owl-item").eq(e.item.index);
            $currentOwlItem.find(".owl-slide-animated").addClass("is-transitioned");
        });
    }

    componentDidMount() {
        this.loadBanners();
        Axios.get(`${Constants.API_URL}homeProducts`).then(res => {
            if (res.status === 200) {
                this.setState({products: res.data});
                this.setState({isLoading: false})
                setTimeout(() => {
                    window.setup();
                }, 1000)
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            <>
                <DocumentTitler/>
                <Shimmer isLoading={this.state.isLoading}>
                    <div className="homeBanner animate"/>
                    <div className="container margin_60_35 mb-5">
                        <div className="main_title animate"><br/><br/></div>
                        <div className="shProductsHolder mt-1">
                            <div className="row">
                                <div className="shProduct col-md-3">
                                    <div className="grid_item">
                                        <div className="shProductImage animate"/>
                                        <div className="shProductTitle animate mt-1"/>
                                        <div className="shProductPrice animate mt-1"/>
                                    </div>
                                </div>
                                <div className="shProduct col-md-3">
                                    <div className="grid_item">
                                        <div className="shProductImage animate"/>
                                        <div className="shProductTitle animate mt-1"/>
                                        <div className="shProductPrice animate mt-1"/>
                                    </div>
                                </div>
                                <div className="shProduct col-md-3">
                                    <div className="grid_item">
                                        <div className="shProductImage animate"/>
                                        <div className="shProductTitle animate mt-1"/>
                                        <div className="shProductPrice animate mt-1"/>
                                    </div>
                                </div>
                                <div className="shProduct col-md-3">
                                    <div className="grid_item">
                                        <div className="shProductImage animate"/>
                                        <div className="shProductTitle animate mt-1"/>
                                        <div className="shProductPrice animate mt-1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <main className="d-none">
                    <div id="carousel-home">
                        <div className="owl-carousel owl-theme">
                            {this.state.banners.map((banner, key) => {
                                return (
                                    <div className="owl-slide cover" style={{backgroundImage: "url(" + encodeURI(banner.banner) + ")"}} key={key}>
                                        <div className="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                                            <div className="container">
                                                <div className="row justify-content-center justify-content-md-end">
                                                    <div className="col-lg-6 static">
                                                        <div className="slide-text text-right white">
                                                            <h2 className="owl-slide-animated owl-slide-title">{banner.title}</h2>
                                                            <div className="owl-slide-animated owl-slide-cta">
                                                                <Link className="btn_1" to={banner.action} role="button">{banner.actionName}</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div id="icon_drag_mobile"/>
                    </div>

                    <ul id="banners_grid" className="clearfix mm-hidden">
                        <li>
                            <Link to="/main/good_books" className="img_container">
                                <img src="/assets/img/banners_cat_placeholder.jpg" data-src="/assets/img/banner_1.jpg" alt="" className="lazy"/>
                                <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                                    <h3>अच्छी किताबें आपकी दोस्त <br/> Good Books Your Friend</h3>
                                    <div><span className="btn_1">Read More</span></div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/main/one_nation" className="img_container">
                                <img src="/assets/img/banners_cat_placeholder.jpg" data-src="/assets/img/banner_2.jpg" alt="" className="lazy"/>
                                <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                                    <h3>एक देश एक दाम <br/> One Nation One Rate</h3>
                                    <div><span className="btn_1">Read More</span></div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <HashLink to="#0" className="img_container">
                                <img src="/assets/img/banners_cat_placeholder.jpg" data-src="/assets/img/banner_3.jpg" alt="" className="lazy"/>
                                <div className="short_info opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.5)">
                                    <h3>डाउनलोड्स <br/> Downloads</h3>
                                    <div><span className="btn_1">Get In</span></div>
                                </div>
                            </HashLink>
                        </li>
                    </ul>
                    {this.state.products.length > 1 && this.state.products.map((product, index) => {
                        return (
                            <div className="container margin_60_35" key={index}>
                                <div className="main_title">
                                    <h2>{product.category}</h2>
                                    <span>Books</span>
                                </div>

                                <div className="owl-carousel owl-theme products_carousel">
                                    {product.items.products !== undefined && product.items.products.map((item, itemKey) => {
                                        return (
                                            <div className="item" key={itemKey}>
                                                <div className="grid_item">
                                                    {item.tagging === Constants.PRODUCT_STATES.NEW ?
                                                        <span className="ribbon new">{Constants.PRODUCT_STATES.NEW}</span> : ""}
                                                    {item.tagging === Constants.PRODUCT_STATES.UnderPublishing ?
                                                        <span className="ribbon new">{Constants.PRODUCT_STATES.UnderPublishing}</span> : ""}
                                                    {item.tagging === Constants.PRODUCT_STATES.UpComing ?
                                                        <span className="ribbon new">{Constants.PRODUCT_STATES.UpComing}</span> : ""}
                                                    {item.inventory === 0 ? <span className="ribbon new">Out of Stock</span> : ""}
                                                    <figure>
                                                        <Link to={`/products/v/${item.id}`}>
                                                            <img className="lazy" src={encodeURI(item.image)} data-src={encodeURI(item.imageHD)} alt=""/>
                                                        </Link>
                                                    </figure>
                                                    <Link to={`/products/v/${item.id}`}><h3>{item.title}</h3></Link>
                                                    <div className="price_box">
                                                        {item.salePrice !== 0 ?
                                                            <><span className="old_price">₹{item.price}</span>&nbsp;
                                                                <span className="new_price">₹{item.salePrice}</span></>
                                                            : <span className="new_price">₹{item.price}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </main>
            </>
        );
    }
}

export default Home;