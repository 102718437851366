import Header from "./Components/Header";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Error404 from "./Components/Errors/Error404";
import ProductView from "./Components/Products/ProductView";
import ScrollToTop from "./Library/ScrollToTop";
import Products from "./Components/Products/Products";
import CartIndex from './Components/Cart/Index'
import React from "react";
import {connect} from "react-redux";
import Search from "./Components/Products/Search";
import Account from "./Components/Account/Account";
import Login from "./Components/Account/Login";
import Logout from "./Components/Account/Logout";
import store from "./Library/Store";
import Profile from "./Components/Account/Profile";
import Orders from "./Components/Account/Orders";
import OrderView from "./Components/Account/OrderView";

const App = () => {

    return (
        <Router>
            <Header/>
            <ScrollToTop/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/products" component={Products}/>
                <Route exact path="/products/page/:page" component={Products}/>
                <Route exact path="/products/page/:page/:category?" component={Products}/>
                <Route exact path="/products/c/:category" component={Products}/>
                <Route exact path="/products/v/:id" component={ProductView}/>
                <Route exact path="/search/:query/:page?" component={Search}/>
                <Route exact path="/cart" component={CartIndex}/>
                <Route exact path="/account">
                    {store.getState().loginHandler.isLoggedIn ? <Profile/> : <Redirect to="/account/login"/>}
                </Route>
                <Route exact path="/account/me">
                    {store.getState().loginHandler.isLoggedIn ? <Profile/> : <Redirect to="/account/login"/>}
                </Route>
                <Route exact path="/account/orders">
                    {store.getState().loginHandler.isLoggedIn ? <Orders/> : <Redirect to="/account/login"/>}
                </Route>
                <Route exact path="/account/orders/view/:orderID">
                    {store.getState().loginHandler.isLoggedIn ? <OrderView/> : <Redirect to="/account/login"/>}
                </Route>
                <Route exact path="/account/login">
                    {!store.getState().loginHandler.isLoggedIn ? <Login/> : <Redirect to="/account"/>}
                </Route>
                <Route exact path="/account/logout" component={Logout}/>
                <Route component={Error404}/>
            </Switch>
            <Footer/>
        </Router>
    );
}

const mapStateToProps = state => ({cart: state.cart, loginHandler: state.loginHandler})

export default connect(mapStateToProps)(App);
