import {Constants} from "../../Components/Constants";

export const addToCart = (product, bundle, cartQty) => {
    return {
        type: Constants.CART_OPERATORS.ADD_TO_CART,
        payload: {
            product: product,
            bundle: bundle,
            itemCount: cartQty
        }
    }
};

export const cartIncrement = (product) => {
    return {
        type: Constants.CART_OPERATORS.CART_INCREMENT,
        payload: product
    }
}

export const cartDecrement = (product) => {
    return {
        type: Constants.CART_OPERATORS.CART_DECREMENT,
        payload: product
    }
}

export const updateCart = (product, bundle, qty) => {
    return {
        type: Constants.CART_OPERATORS.UPDATE_CART_ITEM,
        payload: {
            product: product,
            bundle: bundle,
            quantity: qty
        }
    }
};
export const removeFromCart = (product) => {
    return {
        type: Constants.CART_OPERATORS.REMOVE_FROM_CART,
        payload: product
    }
};