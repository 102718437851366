import {Constants} from "../../Components/Constants";

const initState = {
    isLoggedIn: false,
    userID: 0,
    userName: ""
}
const LoginReducer = (state = initState, action) => {
    switch (action.type) {
        case Constants.LOGIN_OPERATIONS.DO_LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                userID: action.payload.userID,
                userName: action.payload.userName
            };
        case Constants.LOGIN_OPERATIONS.DO_LOGOUT:
            return {...state, isLoggedIn: false, userID: 0, userName: ""};
        default:
            return state;
    }
};

export default LoginReducer;