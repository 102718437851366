import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Constants} from "../Constants";
import Axios from 'axios';
import Shimmer from "../Shimmer";
import Pagination from "../../Library/Pagination";
import store from "../../Library/Store";
import ProductItem from "./ProductItem";
import MobileDetect from "mobile-detect";
import DocumentTitler from "../../Library/DocumentTitler";
import StickyBox from 'react-sticky-box'

const initState = {
    isLoading: true,
    currentPage: 1,
    category: "",
    perPage: 12,
    numProds: 0,
    categories: [
        {
            id: 0,
            name: "",
            param: "",
            prodCount: 0
        }
    ],
    products: [
        {
            id: 0,
            title: "",
            code: "",
            category: "",
            price: 0,
            salePrice: "",
            pages: 0,
            size: "",
            publisher: "",
            inventory: "",
            weight: 0,
            bulkQty: "",
            bulkPrice: "",
            author: "",
            translator: "",
            coupon: "",
            description: "",
            image: "",
            imageHD: "",
            sample: "",
            tagging: "",
            edition: "",
            printing: "",
            paper: "",
            binding: "",
            status: 0
        }
    ]
}
const md = new MobileDetect(navigator.userAgent)

class Products extends Component {

    constructor(props) {
        super(props);
        initState.currentPage = props.match.params.page || 1;
        initState.category = props.match.params.category || "";
        this.state = initState;
    }

    componentDidMount() {
        this.fetchProducts(this.state.currentPage, this.state.category);
        console.log(store.getState());
    }

    fetchProducts(page, cat = "") {
        let category = cat !== "" ? "/" + cat : "";
        Axios.get(`${Constants.API_URL}products/${page}${category}`)
            .then(res => {
                if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                    this.setState({isLoading: false, numProds: res.data.count, categories: res.data.categories, products: res.data.products});
                    setTimeout(() => {
                        window.setup()
                    }, 1000)
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.setState({currentPage: (this.props.match.params.page !== undefined) ? this.props.match.params.page : 1})
            this.setState({category: (this.props.match.params.category !== undefined) ? this.props.match.params.category : ""})
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        this.setState({isLoading: true})
        this.fetchProducts(this.props.match.params.page !== undefined ? this.props.match.params.page : 1,
            this.props.match.params.category !== undefined ? this.props.match.params.category : "")
    }

    paginate = (page, cat = "") => {
        this.setState({currentPage: page, isLoading: true});
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        this.fetchProducts(page, cat);
        let category = cat !== "" ? "/" + cat : "";
        // window.history.replaceState(null, null, `/products/page/${page}`)
        this.props.history.push(`/products/page/${page}${category}`)
    }

    catNavigate(e) {
        this.props.history.push(`/products/c/${e.target.value}`)
    }

    render() {
        const c = this.state.categories.find(cat => cat.param === this.state.category);
        return (
            <>
                <DocumentTitler title="Products"/>

                <Shimmer isLoading={this.state.isLoading}>
                    <div className="productBanner animate"/>
                    <div className="container margin_30">
                        <div className="row">
                            <aside className="col-lg-3 ">
                                <div className="productLeft animate"/>
                            </aside>
                            <div className="col-lg-9 ">
                                <div className="row">
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <main className="d-none">
                    <div className="top_banner">
                        <div className="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                            <div className="container">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        {this.state.category !== "" ?
                                            <>
                                                <li><Link to="/products">Products</Link></li>
                                                <li>{c !== undefined && c.name}</li>
                                            </> :
                                            <li>Products</li>}
                                    </ul>
                                </div>

                                <h1>{this.state.category !== "" ? c !== undefined && c.name : "Products"}</h1>
                            </div>
                        </div>
                    </div>
                    <div id="stick_here"/>
                    <div className="container margin_30">
                        <div className="row">
                            <aside className="col-lg-3" id="sidebar_fixed">
                                <StickyBox offsetTop={90} offsetBottom={20}>
                                    <div className="filter_col">
                                        <div className="inner_bt">
                                            <Link to="#" className="open_filters"><i className="ti-close"/></Link>
                                        </div>
                                        <div className="filter_type version_2">
                                            <h4><a style={{backgroundColor: "#f8f8f8"}}>Categories</a></h4>
                                            <div>
                                                <ul>
                                                    {this.state.categories.map(category => {
                                                        return (<li className={this.state.category === category.param ? "active" : ""}>
                                                            <Link to={`/products/c/${category.param}`}>
                                                                <label className="container_radio pl-0">{category.name}
                                                                    <small>({category.prodCount})</small>
                                                                </label>
                                                            </Link>
                                                        </li>);
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </StickyBox>
                            </aside>
                            <div className="col-lg-9">
                                {md.mobile() || md.phone() !== null ?
                                    <div className="mb-4">
                                        <select className="form-control" onChange={(e) => this.catNavigate(e)}>
                                            <option value="">Categories</option>
                                            {this.state.categories.map(category => {
                                                return (
                                                    <option value={category.param} selected={this.state.category === category.param}>{category.name}</option>);
                                            })}
                                        </select>
                                    </div> : ""}
                                <div className="row small-gutters">
                                    {this.state.products.map((product, key) => {
                                        return (<ProductItem product={product} key={key}/>);
                                    })}
                                </div>
                                <Pagination
                                    postsPerPage={this.state.perPage}
                                    totalPosts={this.state.numProds}
                                    currPage={this.state.currentPage}
                                    paginate={(page) => {
                                        this.paginate(page, this.state.category)
                                    }}/>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Products);