import React, {Component} from 'react';
import store from "../../Library/Store";
import {Link} from "react-router-dom";
import {cartDecrement, cartIncrement, removeFromCart, updateCart} from "../../Library/Actions/cartActions";
import {Helmet} from "react-helmet";
import {Constants} from "../Constants";

const dispatch = store.dispatch;

class Index extends Component {

    updateCartCount(e, product) {
        let bundle = {
            variantID: product.variantID,
            variantPrice: product.variantPrice,
            variantSalePrice: product.variantSalePrice,
        };
        dispatch(updateCart(product, bundle, e.target.value))
    }

    getVariantPrice(product, qty = 1) {
        let salePrice = product.variantSalePrice !== 0 ? product.variantSalePrice * qty : product.salePrice * qty;
        let basePrice = product.variantPrice !== 0 ? product.variantPrice * qty : product.price * qty;
        return (salePrice !== 0 ? <><strike> ₹{basePrice}</strike> <br/> <strong>₹{salePrice}</strong></> : <>₹{basePrice}</>);
    }

    render() {
        const cart = store.getState().cart;
        return (
            <>
                <Helmet>
                    <title>Cart :: {Constants.COMPANY.NAME}</title>
                </Helmet>
                <main className="bg_gray">
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Cart</li>
                                </ul>
                            </div>
                            <h1>Cart</h1>
                        </div>
                        {(cart.products.length > 0) ?
                            <>
                                <table className="table table-striped cart-list">
                                    <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Subtotal</th>
                                        <th/>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(cart.products.length > 0) ?
                                        cart.products.map((product, key) => {
                                            return (<tr key={key}>
                                                <td>
                                                    <div className="thumb_cart">
                                                        <Link to={`/products/v/${product.id}`}><img src={product.image} alt={product.title}/></Link>
                                                    </div>
                                                    <Link to={`/products/v/${product.id}`}><span className="item_cart">{product.title}</span></Link>
                                                </td>
                                                <td>
                                                    {this.getVariantPrice(product)}
                                                </td>
                                                <td>
                                                    <div className="numbers-row">
                                                        <input type="text" className="qty2" value={product.itemQty} step="1" min="1"
                                                               name="quantity" title="Qty" onChange={(e) => {
                                                            this.updateCartCount(e, product)
                                                        }}/>
                                                        <div className="inc button_inc" onClick={() => {
                                                            dispatch(cartIncrement(product))
                                                        }}>+
                                                        </div>
                                                        <div className="dec button_inc" onClick={() => {
                                                            dispatch(cartDecrement(product))
                                                        }}>-
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {this.getVariantPrice(product, product.itemQty)}
                                                </td>
                                                <td className="options">
                                                    <a className="remove" onClick={() => {
                                                        dispatch(removeFromCart(product))
                                                    }}><i className="ti-trash"/></a>
                                                </td>
                                            </tr>)
                                        }) :
                                        <tr>
                                            <td style={{verticalAlign: "middle"}} colSpan='4'>Oops It Seems your Cart is empty!</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                <div className="row add_top_30 flex-sm-row-reverse cart_actions">
                                    <div className="col-sm-4 text-right"/>
                                    <div className="col-sm-8">
                                        <div className="apply-coupon">
                                            <div className="form-group form-inline">
                                                <input type="text" name="coupon-code" placeholder="Promo code" className="form-control"/>
                                                <button type="button" className="btn_1 outline">Apply Coupon</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box_cart">
                                    <div className="container">
                                        <div className="row justify-content-end">
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <ul>
                                                    <li>
                                                        <span>Subtotal</span>
                                                        <div className="cTotal">₹{cart.cartTotalAmount}</div>
                                                    </li>
                                                    <li>
                                                        <span>Shipping</span> ₹0
                                                    </li>
                                                    <li>
                                                        <span>Total</span>
                                                        <div className="cTotal">₹{cart.cartTotalAmount + 0}</div>
                                                    </li>
                                                </ul>
                                                <a className="btn_1 full-width cart">Proceed to Checkout</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <h2 className="mt-5 mb-5">Nothing found in your cart.</h2>
                        }
                    </div>
                </main>
            </>
        );
    }
}

export default Index;