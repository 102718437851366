import React, {Component} from 'react';
import Axios from 'axios';
import {Constants} from "../Constants";
import store from "../../Library/Store";
import Shimmer from "../Shimmer";
import ShimmerElement from "../ShimmerElement";

const initState = {
    isLoading: true,
    profile:
        {
            userID: 0,
            fullName: "",
            mobile: "",
            email: "",
            accountSince: "",
            numOrders: ""
        }
    ,
    addresses: [
        {
            addressID: 0,
            fullName: "",
            mobile: "",
            addLine1: "",
            addLine2: "",
            addType: "",
            landMark: "",
            city: "",
            state: "",
            postalCode: ""
        }
    ]
}

const loginHandler = store.getState().loginHandler;

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = initState;
    }

    componentDidMount() {
        Axios.get(`${Constants.API_URL}profile/${loginHandler.userID}`)
            .then(res => {
                console.log(res.data);
                this.setState({isLoading: false, profile: res.data.profile, addresses: res.data.addresses});
                setTimeout(() => {
                    window.setup()
                }, 1000)
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("update", this.state)
    }

    render() {
        return (
            <>
                <Shimmer isLoading={this.state.isLoading}>
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <div className="row col-md-12">
                                    <ShimmerElement width="w30x" height="h15"/> &nbsp;&nbsp;
                                    <ShimmerElement width="w60x" height="h15"/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="box_account">
                                    <ShimmerElement width="w20" height="h25"/> <br/>
                                    <div className="form_container">
                                        <div className="row">
                                            <ShimmerElement width="col-md-2" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h20"/>
                                        </div>
                                        <div className="row mt-3">
                                            <ShimmerElement width="col-md-3" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h20"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h20"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box_account">
                            <ShimmerElement width="w20" height="h25"/> <br/>
                            <div className="row">
                                <div className="col-md-4 mb-1">
                                    <div className="form_container ">
                                        <ShimmerElement width="w50" height="h25"/>
                                        <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w50" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w30" height="h15" marginTop="mt-2"/>
                                        <div className="row col-md-12 mt-2">
                                            <ShimmerElement width="w80" height="h15"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        </div>
                                        <div className="controls" style={{marginTop: "10px"}}>
                                            <div className="row col-md-12">
                                                <ShimmerElement width="w40" height="h35"/> &nbsp;&nbsp;
                                                <ShimmerElement width="w40" height="h35"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-1">
                                    <div className="form_container ">
                                        <ShimmerElement width="w50" height="h25"/>
                                        <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w50" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w30" height="h15" marginTop="mt-2"/>
                                        <div className="row col-md-12 mt-2">
                                            <ShimmerElement width="w80" height="h15"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        </div>
                                        <div className="controls" style={{marginTop: "10px"}}>
                                            <div className="row col-md-12">
                                                <ShimmerElement width="w40" height="h35"/> &nbsp;&nbsp;
                                                <ShimmerElement width="w40" height="h35"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-1">
                                    <div className="form_container ">
                                        <ShimmerElement width="w50" height="h25"/>
                                        <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w50" height="h15" marginTop="mt-2"/>
                                        <ShimmerElement width="w30" height="h15" marginTop="mt-2"/>
                                        <div className="row col-md-12 mt-2">
                                            <ShimmerElement width="w80" height="h15"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                            <ShimmerElement width="w80" height="h15" marginTop="mt-2"/>
                                        </div>
                                        <div className="controls" style={{marginTop: "10px"}}>
                                            <div className="row col-md-12">
                                                <ShimmerElement width="w40" height="h35"/> &nbsp;&nbsp;
                                                <ShimmerElement width="w40" height="h35"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <main className="bg_gray d-none">
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Profile</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="box_account">
                                    <h3 className="pl-0" style={{width: "100%"}}>Profile</h3>
                                    <div className="form_container">
                                        <table className="table mb-5">
                                            <tbody>
                                            <tr>
                                                <td>Account Name:</td>
                                                <th>{!this.state.isLoading && this.state.profile.fullName}</th>
                                                <td>Registered Mobile:</td>
                                                <th>{!this.state.isLoading && this.state.profile.mobile}</th>
                                            </tr>
                                            <tr>
                                                <td>Email Address:</td>
                                                <th>{!this.state.isLoading && this.state.profile.email}</th>
                                                <td>Account Since:</td>
                                                <th>{!this.state.isLoading && this.state.profile.accountSince}</th>
                                            </tr>
                                            <tr>
                                                <td>Order(s) Placed:</td>
                                                <th>{!this.state.isLoading && this.state.profile.numOrders}</th>
                                                <td/>
                                                <th/>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box_account">
                            <h3 className="pl-0" style={{width: "100%"}}>My Addresses</h3>
                            <div className="row">
                                {this.state.addresses.length > 0 ?
                                    this.state.addresses.map(address => {
                                        return (
                                            <div className="col-md-4 mb-1">
                                                <div className="form_container ">
                                                    <h5>{address.fullName}</h5>
                                                    <span>{address.addLine1} {address.addLine2}</span>
                                                    <span style={{display: "block"}}>{address.city}, {address.state} - {address.postalCode}</span>
                                                    <span>India</span>
                                                    <div className="row mt-1">
                                                        <div className="col-md-12 mb-1">
                                                            Landmark: <strong>{address.landMark}</strong>
                                                        </div>
                                                        <div className="col-md-12 mb-1">
                                                            Address Type: <strong>{address.addType.toUpperCase()}</strong>
                                                        </div>
                                                        <div className="col-md-12">
                                                            Mobile: <strong>{address.mobile}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="controls" style={{marginTop: "10px"}}>
                                                        <button type="button" className="btn_1">Edit</button>
                                                        &nbsp;&nbsp;
                                                        <button type="button" className="btn_1 outline">Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : "<h5>No addresses found in your account.</h5>"}
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Profile;