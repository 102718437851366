import {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./Reducers/reducers";

import {createStateSyncMiddleware, initStateWithPrevTab} from 'redux-state-sync'

const config = {}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middlewares = [
    createStateSyncMiddleware(config),
]

const saveToStorage = (state) => {
    try {
        localStorage.setItem('state', JSON.stringify(state));

    } catch (e) {
        console.error(e)
    }
}

const loadFromStorage = () => {
    try {
        const stateStr = localStorage.getItem('state');
        return stateStr ? JSON.parse(stateStr) : undefined;
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

const persistedStore = loadFromStorage();

const store = createStore(rootReducer, persistedStore, composeEnhancers(applyMiddleware(...middlewares)));
initStateWithPrevTab(store);
store.subscribe(() => {
    saveToStorage(store.getState());
});

export default store;