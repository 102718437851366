import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import Shimmer from "../Shimmer";
import ShimmerElement from "../ShimmerElement";
import Axios from 'axios';
import {Constants} from "../Constants";
import DocumentTitler from "../../Library/DocumentTitler";
import Error404 from "../Errors/Error404";

const initState = {
    isLoading: true,
    orderID: "",
    orders: {
        id: 0,
        orderID: "",
        coupon: "",
        orderType: "",
        orderDate: "",
        discount: "",
        amount: "",
        shipping: "",
        netAmount: "",
        deliverTo: "",
        deliveryStat: "",
        payMode: "",
        payStat: "",
        orderBy: "",
        transID: "",
        payThru: "",
        payBy: "",
    },
    orderDetails: [
        {
            itemID: 0,
            productID: 0,
            productName: "",
            productPrice: "",
            productQty: 0
        }
    ]
}

class OrderView extends Component {
    constructor(props) {
        super(props);
        initState.orderID = props.match.params.orderID || "";
        this.state = initState;
    }

    componentDidMount() {
        if (this.state.orderID !== "") {
            Axios.get(`${Constants.API_URL}orderDetails/${this.state.orderID}`)
                .then(res => {
                    if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                        if (res.data.orderDetails !== undefined)
                            this.setState({isLoading: false, orders: res.data.orders, orderDetails: res.data.orderDetails})
                        else
                            this.setState({isLoading: false});
                        console.log(this.state.orders.id)
                    }
                })
        }
    }

    render() {
        return (
            <>
                <Shimmer isLoading={this.state.isLoading}>
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <div className="row col-md-12">
                                    <ShimmerElement width="w30x" height="h15"/> &nbsp;&nbsp;
                                    <ShimmerElement width="w60x" height="h15"/>&nbsp;&nbsp;
                                    <ShimmerElement width="w90x" height="h15"/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="box_account">
                                    <h3 className="pl-0 mb-5" style={{width: "100%"}}>
                                        <div className="w30" style={{float: "left", width: "80%"}}>
                                            <ShimmerElement width="w20" height="h30"/>
                                        </div>
                                        <div className="w30" style={{float: "right", width: "20%"}} align="right">
                                            <ShimmerElement width="w60" height="h35"/>
                                        </div>
                                    </h3>
                                    <div className="form_container">
                                        <div className="row">
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w30" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                        <div className="row mt-2">
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w30" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w40" height="h25"/>
                                        </div>
                                        <div className="row mt-2">
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w30" height="h25"/> &nbsp;&nbsp;&nbsp;

                                        </div>
                                        <div className="row mt-5">
                                            <ShimmerElement width="w60x" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                        <div className="row mt-2">
                                            <ShimmerElement width="w60x" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                        <div className="row mt-1">
                                            <ShimmerElement width="w60x" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                        <div className="row mt-1">
                                            <ShimmerElement width="w60x" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                        <div className="row mt-1">
                                            <ShimmerElement width="w60x" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="w20" height="h25"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <DocumentTitler title={`Order Details > ${this.state.orderID}`}/>
                {this.state.orders.id > 0 ?
                    <main className="bg_gray d-none">
                        <div className="container margin_30">
                            <div className="page_header">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/account/orders">Orders</Link></li>
                                        <li>Order Details > {this.state.orderID}</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12 col-md-12">
                                    <div className="box_account">
                                        <h3 className="pl-0 mb-5" style={{width: "100%"}}>
                                            Order Details > {this.state.orderID}
                                            <Link to="/account/orders" className="btn_1 outline " style={{float: "right"}}>Back to orders</Link>
                                        </h3>
                                        <div className="form_container">
                                            <table className="table table-striped">
                                                <tr>
                                                    <td>Order ID</td>
                                                    <th>{this.state.orders.orderID}</th>
                                                    <td>Delivery to:</td>
                                                </tr>
                                                <tr>
                                                    <td>Order Date</td>
                                                    <th>{this.state.orders.orderDate}</th>
                                                    <td rowSpan="2" dangerouslySetInnerHTML={{__html: this.state.orders.deliverTo}}/>
                                                </tr>
                                                <tr>
                                                    <td>Payment Method</td>
                                                    <th>{this.state.orders.payMode.toUpperCase()}</th>
                                                </tr>
                                            </table>
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Book Title</th>
                                                    <th>Book Price</th>
                                                    <th>Quantity</th>
                                                    <th>Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.orderDetails.length > 0 ?
                                                    this.state.orderDetails.map((orderDetail, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <Link
                                                                        to={`/products/v/${orderDetail.productID}`}><strong>{orderDetail.productName}</strong></Link>
                                                                </td>
                                                                <td>₹ {orderDetail.productPrice}</td>
                                                                <td>{orderDetail.productQty}</td>
                                                                <td>₹ {(orderDetail.productPrice * orderDetail.productQty).toFixed(2)}</td>
                                                            </tr>
                                                        )
                                                    }) : <tr>
                                                        <td colSpan="5">No records found...</td>
                                                    </tr>}
                                                </tbody>
                                                {this.state.orderDetails.length > 0 ?
                                                    <tfoot>
                                                    <tr>
                                                        <td colSpan="4" className="text-right">Amount</td>
                                                        <td><strong>{this.state.orders.amount}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="text-right">Shipping</td>
                                                        <td><strong>{this.state.orders.shipping}</strong></td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="4" className="text-right">Total</td>
                                                        <td><strong>{this.state.orders.netAmount}</strong></td>
                                                    </tr>
                                                    </tfoot>
                                                    : ""}
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    :
                    !this.state.isLoading && <Error404/>}
            </>
        );
    }
}

export default withRouter(OrderView);