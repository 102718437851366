import React from 'react';
import DocumentTitler from "../../Library/DocumentTitler";

function Error404() {
    return (
        <>
            <DocumentTitler title="Oops!! Page not found..."/>
            <main className="bg_gray">
                <div id="error_page">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-xl-7 col-lg-9">
                                <img src="/assets/img/404.svg" alt="" className="img-fluid" width="400" height="212"/>
                                <p>The page you're looking is not founded!</p>
                                <form>
                                    <div className="search_bar">
                                        <input type="text" className="form-control" placeholder="What are you looking for?"/>
                                        <input type="submit" value="Search"/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Error404;