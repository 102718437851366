import React, {Component} from 'react';
import {$} from 'react-jquery-plugin'
import DocumentTitler from "../../Library/DocumentTitler";
import Axios from "axios";
import {Constants} from "../Constants";
import {Link, withRouter} from "react-router-dom";
import store from "../../Library/Store";
import {doLogin} from "../../Library/Actions/loginActions";

const initState = {
    error: "",
    user: "",
    email: "",
    password: "",
    passwordReg: "",
    name: "",
    fullAddress: "",
    city: "",
    postalCode: "",
    country: "",
    mobile: ""
}

const loginHandler = store.getState();
const dispatch = store.dispatch;

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
    }

    componentDidMount() {
        setTimeout(() => {
            window.setup()
        }, 1000)

    }

    eHandle(e) {
        this.setState({[e.target.id]: e.target.value})
    }

    doLogin(e) {
        e.preventDefault();
        const mThis = this;
        const handler = $(e.target).find("#submit");
        const oldVal = handler.val();
        handler.val("Logging in...")
        Axios.post(`${Constants.API_URL}checkLogin`, {user: this.state.user, pass: this.state.password})
            .then(res => {
                    if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                        if (!res.data.error) {
                            dispatch(doLogin({isLoggedIn: true, userID: res.data.userID, userName: res.data.userName}))
                            handler.val(oldVal)
                            this.setState({error: ""})
                            setTimeout(() => {
                                mThis.props.history.push("/account");
                            }, 1000);
                        } else {
                            handler.val(oldVal)
                            this.setState({error: res.data.msg})
                        }
                    }
                }
            );
    }

    doRegister(e) {
        e.preventDefault();
    }

    render() {
        return (
            <>
                <DocumentTitler title="My Account"/>
                <main className="bg_gray">
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li>Account</li>
                                </ul>
                            </div>
                            <h1>Sign In or Create an Account</h1>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-6 col-md-8">
                                <div className="box_account">
                                    <h3 className="client">Already Consumer</h3>
                                    <div className="form_container">
                                        <form onSubmit={(e) => {
                                            this.doLogin(e)
                                        }} method="post">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="user" placeholder="Email*" required
                                                       value={this.state.user} onChange={(e) => {
                                                    this.eHandle(e);
                                                }} autoComplete="off"/>
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control" id="password" value={this.state.password}
                                                       onChange={(e) => {
                                                           this.eHandle(e);
                                                       }} autoComplete="off" placeholder="Password*"/>
                                            </div>
                                            <div className="clearfix add_bottom_15">
                                                <div className="float-right"><Link id="forgot" to="#">Lost Password?</Link></div>
                                            </div>
                                            {this.state.error !== "" ?
                                                <div className='alert-message alert alert-notice rounded mb-3'><strong>{this.state.error}</strong></div>
                                                : ""}
                                            <div className="text-center">
                                                <input type="submit" value="Log In" id="submit" className="btn_1 full-width"/>
                                            </div>
                                        </form>
                                        <form>
                                            <div id="forgot_pw">
                                                <div className="form-group">
                                                    <input type="email" className="form-control" name="email_forgot" id="email_forgot"
                                                           placeholder="Type your email"/>
                                                </div>
                                                <p>A new password will be sent shortly.</p>
                                                <div className="text-center">
                                                    <input type="submit" value="Reset Password" className="btn_1"/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-8">
                                <div className="box_account">
                                    <h3 className="new_client">New Consumer</h3>
                                    <small className="float-right pt-2">* Required Fields</small>
                                    <form onSubmit={(e) => {
                                        this.doRegister(e)
                                    }} method="post">
                                        <div className="form_container">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="email" value={this.state.email} onChange={(e) => {
                                                    this.eHandle(e);
                                                }} placeholder="Email*"/>
                                            </div>
                                            <div className="form-group">
                                                <input type="password" className="form-control" id="passwordReg" value={this.state.passwordReg}
                                                       onChange={(e) => {
                                                           this.eHandle(e);
                                                       }} placeholder="Password*"/>
                                            </div>
                                            <hr/>
                                            <div className="private box">
                                                <div className="row no-gutters">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Name*" value={this.state.name}
                                                                   onChange={(e) => {
                                                                       this.eHandle(e);
                                                                   }} id="name"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Full Address*"
                                                                   value={this.state.fullAddress} onChange={(e) => {
                                                                this.eHandle(e);
                                                            }} id="fullAddress"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters">
                                                    <div className="col-6 pr-1">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="City*" value={this.state.city}
                                                                   onChange={(e) => {
                                                                       this.eHandle(e);
                                                                   }} id="city"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-1">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Postal Code*"
                                                                   value={this.state.postalCode}
                                                                   onChange={(e) => {
                                                                       this.eHandle(e);
                                                                   }} id="postalCode"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row no-gutters">
                                                    <div className="col-6 pr-1">
                                                        <div className="form-group">
                                                            <div className="custom-select-form">
                                                                <select className="wide add_bottom_10" id="country" value={this.state.country}
                                                                        onChange={(e) => {
                                                                            this.eHandle(e)
                                                                        }}>
                                                                    <option value="India">India</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 pl-1">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" placeholder="Mobile *"
                                                                   value={this.state.mobile}
                                                                   onChange={(e) => {
                                                                       this.eHandle(e)
                                                                   }} id="mobile"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="text-center">
                                                <input type="submit" value="Register" className="btn_1 full-width"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Login);