import React, {Component} from 'react';
import {Constants} from "./Constants";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import Axios from 'axios';
import {$} from 'react-jquery-plugin'

const initState = {
    categories: []
}

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
    }


    componentDidMount() {
        /*const script = document.createElement("script");
        script.src = "/assets/js/main.js";
        script.async = true;
        document.body.appendChild(script);*/

        Axios.get(`${Constants.API_URL}footerCategories`).then(res => {
            this.cats = res.data;
            this.setState({categories: res.data})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return (
            <>
                <footer className="revealed">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <h3 data-target="#collapse_1">Quick Links</h3>
                                <div className="collapse dont-collapse-sm links" id="collapse_1">
                                    <ul>
                                        <li><Link to="/main/about">About us</Link></li>
                                        <li><Link to="/account/orders">My orders</Link></li>
                                        <li><Link to="/account">My account</Link></li>
                                        <li><Link to="/contact">Contacts</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h3 data-target="#collapse_2">Categories</h3>
                                <div className="collapse dont-collapse-sm links" id="collapse_2">
                                    <ul className="fooCategory">
                                        {this.state.categories.map((cat, index) => {
                                            if (index < 5) return (<li key={index}><Link to={`/products/c/${cat.param}`}>{cat.cat_name}</Link></li>)
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <h3 data-target="#collapse_3">Contacts</h3>
                                <div className="collapse dont-collapse-sm contacts" id="collapse_3">
                                    <ul itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                                        <li><i className="ti-home"/>
                                            <span itemProp="streetAddress">{Constants.COMPANY.ADDRESS}</span>&nbsp;
                                            <span itemProp="addressLocality">{Constants.COMPANY.LOCALITY}</span>&nbsp;
                                            <span itemProp="addressRegion">{Constants.COMPANY.REGION}</span>&nbsp;
                                            <span itemProp="postalCode">{Constants.COMPANY.POSTAL}</span>&nbsp;
                                            <span itemProp="addressCountry">{Constants.COMPANY.COUNTRY}</span></li>
                                        <li><i className="ti-headphone-alt"/><Link target="_top"
                                                                                   to={{pathname: `tel:${Constants.COMPANY.CONTACT}`}}>{Constants.COMPANY.CONTACT}</Link>
                                        </li>
                                        <li><i className="ti-email"/><Link to={{pathname: `mailto:${Constants.COMPANY.EMAIL}`}}
                                                                           target="_top">adarshmuslim@gmail.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row add_bottom_25">
                            <div className="col-lg-4"/>
                            <div className="col-lg-8">
                                <ul className="additional_links">
                                    <li><Link to="/main/refund_policy">Returns/Refund Policy</Link></li>
                                    <li><Link to="/main/privacy_policy">Privacy Policy</Link></li>
                                    <li><Link to="/main/terms">Terms &amp; Conditions</Link></li>
                                    <li><span>© 2020 {Constants.COMPANY.NAME}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <div id="toTop"/>
                <Helmet>
                    {/*<script src="/assets/js/main.js"/>*/}
                    {/* <script src="/assets/js/sticky_sidebar.min.js"/>
                    <script src="/assets/js/specific_listing.js"/>*/}
                </Helmet>
            </>
        );
    }
}

export default Footer;