import React from 'react';
import {Helmet} from "react-helmet-async";
import {Constants} from "../Components/Constants";

const DocumentTitler = ({title}) => {
    return (
        <Helmet prioritizeSeoTags>
            <title>{title !== "" ? `${title} :: `: ""}{Constants.COMPANY.NAME}</title>
        </Helmet>
    );
};

DocumentTitler.defaultProps = {
    title: ""
}
export default DocumentTitler;