import React, {Component} from 'react';
import DocumentTitler from "../../Library/DocumentTitler";
import Shimmer from "../Shimmer";
import ShimmerElement from "../ShimmerElement";
import {Link, withRouter} from "react-router-dom";
import Axios from "axios";
import store from "../../Library/Store";
import {Constants} from "../Constants";

const initState = {
    isLoading: true,
    orders:
        [{
            id: 0,
            orderID: "",
            orderDate: "",
            netAmount: 0,
            deliveryStat: ""
        }]
}

const loginHandler = store.getState().loginHandler;

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
    }

    componentDidMount() {
        Axios.get(`${Constants.API_URL}orders/${loginHandler.userID}`)
            .then(res => {
                if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                    this.setState({isLoading: false, orders: res.data.orders})
                }
            })
    }

    render() {
        return (
            <>
                <DocumentTitler title="My Orders"/>
                <Shimmer>
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <div className="row col-md-12">
                                    <ShimmerElement width="w30x" height="h15"/> &nbsp;&nbsp;
                                    <ShimmerElement width="w60x" height="h15"/>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="box_account">
                                    <ShimmerElement width="w20" height="h25"/> <br/>
                                    <div className="form_container">
                                        <div className="row">
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/>
                                        </div>
                                        <div className="row mt-3">
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/>
                                        </div>
                                        <div className="row mt-2">
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/>
                                        </div>
                                        <div className="row mt-2">
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-2" height="h25"/> &nbsp;&nbsp;&nbsp;
                                            <ShimmerElement width="col-md-3" height="h25"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <main className="bg_gray d-none">
                    <div className="container margin_30">
                        <div className="page_header">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>My Orders</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="box_account">
                                    <h3 className="pl-0 mb-3" style={{width: "100%"}}>My Orders</h3>
                                    <div className="form_container">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Order ID</th>
                                                <th>Order Date</th>
                                                <th>Order Value</th>
                                                <th>Order Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.orders !== undefined && this.state.orders.length > 0 ?
                                                this.state.orders.map((order, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td><Link to={`/account/orders/view/${order.orderID}`}><strong>{order.orderID}</strong></Link></td>
                                                            <td>{order.orderDate}</td>
                                                            <td>{order.netAmount}</td>
                                                            <td>{order.deliveryStat}</td>
                                                        </tr>
                                                    )
                                                }) : <tr>
                                                    <td colSpan="5">No records found...</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Orders);