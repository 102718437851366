export const Constants = {
    API_URL: "https://adarshmuslim.in/web_api/",
    COMPANY: {
        NAME: "Adarsh Muslim Publication",
        ADDRESS: "Shop No. 30-A, Stadium Shopping Center Near Haj House",
        LOCALITY: "Jodhpur",
        REGION: "Rajasthan",
        POSTAL: "342001",
        COUNTRY: "India",
        CONTACT: "+919829346786",
        EMAIL: "adarshmuslim@gmail.com"
    },
    PRODUCT_STATES: {
        NEW: "New",
        UnderPublishing: "Under Publishing",
        UpComing: "Upcoming",
    },
    SERVER_RESPONSE: {
        RESPONSE_OK: 200,
        RESPONSE_ERROR: 400
    },
    CART_OPERATORS: {
        ADD_TO_CART: "ADD_TO_CART",
        CART_INCREMENT: "CART_INCREMENT",
        CART_DECREMENT: "CART_DECREMENT",
        UPDATE_CART_ITEM: "UPDATE_CART_ITEM",
        REMOVE_FROM_CART: "REMOVE_FROM_CART"
    },
    LOGIN_OPERATIONS: {
        DO_LOGIN: "DO_LOGIN",
        DO_REGISTER: "DO_REGISTER",
        DO_LOGOUT: "DO_LOGOUT",
    },
    SESS_IS_LOGGED_IN: "isLoggedIn",
    SESS_USER_ID: "userID",
    SESS_USER_NAME: "userName",
}