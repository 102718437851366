import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Constants} from "../Constants";
import Axios from 'axios';
import Shimmer from "../Shimmer";
import Pagination from "../../Library/Pagination";
import {Helmet} from "react-helmet";
import ProductItem from "./ProductItem";

const initState = {
    isLoading: true,
    currentPage: 1,
    query: "",
    perPage: 12,
    numProds: 0,
    products: [
        {
            id: 0,
            title: "",
            code: "",
            category: "",
            price: 0,
            salePrice: "",
            pages: 0,
            size: "",
            publisher: "",
            inventory: "",
            weight: 0,
            bulkQty: "",
            bulkPrice: "",
            author: "",
            translator: "",
            coupon: "",
            description: "",
            image: "",
            imageHD: "",
            sample: "",
            tagging: "",
            edition: "",
            printing: "",
            paper: "",
            binding: "",
            status: 0
        }
    ]
}

class Search extends Component {

    constructor(props) {
        super(props);
        initState.currentPage = props.match.params.page || 1;
        initState.query = props.match.params.query || "";
        this.state = initState;
    }

    componentDidMount() {
        this.setState({query: this.props.match.params.query})
        this.fetchProducts(this.state.query, this.state.currentPage);
    }

    fetchProducts(query, page) {
        Axios.get(`${Constants.API_URL}searchResults/${query}/${page}`)
            .then(res => {
                if (res.status === Constants.SERVER_RESPONSE.RESPONSE_OK) {
                    this.setState({isLoading: false, numProds: res.data.count, products: res.data.products});
                    setTimeout(() => {
                        window.setup()
                    }, 1000)
                }
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.setState({query: this.props.match.params.query, currentPage: (this.props.match.params.page !== undefined) ? this.props.match.params.page : 1})
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        this.setState({isLoading: true})
        let query = (this.props.match.params.query !== undefined) ? this.props.match.params.query : ""
        let page = (this.props.match.params.page !== undefined) ? this.props.match.params.page : 1;
        this.fetchProducts(query, page)
    }

    paginate = (page) => {
        this.setState({currentPage: page, isLoading: true});
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        this.fetchProducts(this.state.query, page);
        // window.history.replaceState(null, null, `/products/page/${page}`)
        this.props.history.push(`/search/${this.state.query}/${page}`)
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Search Results for () :: {Constants.COMPANY.NAME}</title>
                </Helmet>
                <Shimmer isLoading={this.state.isLoading}>
                    <div className="productBanner animate"/>
                    <div className="container margin_30">
                        <div className="row">
                            <div className="col-lg-12 ">
                                <div className="row">
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                    <div className="shProduct col-md-4">
                                        <div className="grid_item">
                                            <div className="shProductImage animate"/>
                                            <div className="shProductTitle animate mt-1"/>
                                            <div className="shProductPrice animate mt-1"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Shimmer>
                <main className="d-none">
                    <div className="top_banner">
                        <div className="opacity-mask d-flex align-items-center" data-opacity-mask="rgba(0, 0, 0, 0.3)">
                            <div className="container">
                                <div className="breadcrumbs">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/products">Products</Link></li>
                                        <li>Search Results for <em style={{textTransform: "lowercase"}}>'{this.state.query}'</em></li>
                                    </ul>
                                </div>
                                <h1>Search Results for <em style={{textTransform: "lowercase"}}>'{this.state.query}'</em></h1>
                            </div>
                        </div>
                    </div>
                    <div id="stick_here"/>
                    <div className="container margin_30">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row small-gutters">
                                    {this.state.products.length > 0 ?
                                        this.state.products.map((product, key) => {
                                            return (<ProductItem product={product} key={key} itemGrid={5}/>);
                                        }) : "No results found..."}
                                </div>
                                <Pagination
                                    postsPerPage={this.state.perPage}
                                    totalPosts={this.state.numProds}
                                    paginate={(page) => {
                                        this.paginate(page)
                                    }}/>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Search);