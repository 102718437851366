import {Constants} from "../../Components/Constants";

const initialState = {
    products: [],
    cartTotalItems: 0,
    cartTotalAmount: 0
};
const cartReducer = (state = initialState, action) => {
    let findProduct;
    let productIndex;
    let basePrice;
    let bundlePrice;
    switch (action.type) {
        case Constants.CART_OPERATORS.ADD_TO_CART:
            const {product, bundle, itemCount} = action.payload
            const isExist = state.products.find(itm => itm.id === product.id);
            if (isExist)
                return state;
            else {
                product.itemQty = parseInt(itemCount) || 1;
                basePrice = product.salePrice !== 0 ? product.salePrice : product.price;
                bundlePrice = bundle.variantSalePrice !== 0 ? bundle.variantSalePrice : bundle.variantPrice;
                product.variantID = bundle.variantID;
                product.variantSalePrice = bundle.variantSalePrice;
                product.variantPrice = bundle.variantPrice;
                return {
                    ...state,
                    products: [...state.products, product],
                    cartTotalAmount: (state.cartTotalAmount + (bundlePrice ? bundlePrice : basePrice * itemCount)),
                    cartTotalItems: state.cartTotalItems + 1
                }
            }
        case Constants.CART_OPERATORS.CART_INCREMENT:
            findProduct = state.products.find(itm => itm.id === action.payload.id);
            productIndex = state.products.indexOf(findProduct);

            basePrice = findProduct.salePrice !== 0 ? findProduct.salePrice : findProduct.price;
            bundlePrice = findProduct.variantSalePrice !== 0 ? findProduct.variantSalePrice : findProduct.variantPrice;

            findProduct.itemQty += 1;
            state.products[productIndex] = findProduct;
            return {...state, cartTotalAmount: state.cartTotalAmount + (bundlePrice !== 0 ? bundlePrice : basePrice)};
        case Constants.CART_OPERATORS.CART_DECREMENT:
            findProduct = state.products.find(itm => itm.id === action.payload.id);
            productIndex = state.products.indexOf(findProduct);

            basePrice = findProduct.salePrice !== 0 ? findProduct.salePrice : findProduct.price;
            bundlePrice = findProduct.variantSalePrice !== 0 ? findProduct.variantSalePrice : findProduct.variantPrice;

            if (findProduct.itemQty > 1) {
                findProduct.itemQty -= 1;
                state.products[productIndex] = findProduct;
                return {...state, cartTotalAmount: state.cartTotalAmount - (bundlePrice !== 0 ? bundlePrice : basePrice)};
            }
            return state;
        case Constants.CART_OPERATORS.UPDATE_CART_ITEM:
            const {quantity} = action.payload;
            let uBundle = action.payload.bundle;


            findProduct = state.products.find(itm => itm.id === action.payload.product.id);
            productIndex = state.products.indexOf(findProduct);

            basePrice = findProduct.salePrice !== 0 ? findProduct.salePrice : findProduct.price;
            let baseBundlePrice = findProduct.variantSalePrice !== 0 ? findProduct.variantSalePrice : findProduct.variantPrice;
            bundlePrice = uBundle.variantSalePrice !== 0 ? uBundle.variantSalePrice : uBundle.variantPrice;

            findProduct.variantID = uBundle.variantID;
            findProduct.variantSalePrice = uBundle.variantSalePrice;
            findProduct.variantPrice = uBundle.variantPrice;

            const oldAmount = (baseBundlePrice !== 0 ? baseBundlePrice : basePrice) * findProduct.itemQty;

            if (parseInt(quantity) > 0) findProduct.itemQty = parseInt(quantity);
            else findProduct.itemQty = 1;

            state.products[productIndex] = findProduct;
            return {
                ...state,
                cartTotalAmount: state.cartTotalAmount - oldAmount + (bundlePrice !== 0 ? bundlePrice * quantity : basePrice * quantity)
            };
        case Constants.CART_OPERATORS.REMOVE_FROM_CART:
            findProduct = state.products.find(itm => itm.id === action.payload.id);
            productIndex = state.products.indexOf(findProduct)
            basePrice = findProduct.salePrice !== 0 ? findProduct.salePrice : findProduct.price;
            bundlePrice = findProduct.variantSalePrice !== 0 ? findProduct.variantSalePrice : findProduct.variantPrice;
            const filtered = state.products.filter(itm => itm.id !== findProduct.id)
            if (filtered.length > 0)
                return {
                    ...state,
                    products: filtered,
                    cartTotalItems: state.cartTotalItems - 1,
                    cartTotalAmount: state.cartTotalAmount - (bundlePrice !== 0 ? bundlePrice * findProduct.itemQty : basePrice * findProduct.itemQty)
                }
            else
                return {
                    ...state,
                    products: [],
                    cartTotalItems: 0,
                    cartTotalAmount: 0
                }
        default:
            return state;
    }
};

export default cartReducer;