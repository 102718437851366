import {Constants} from "../../Components/Constants";

export const doLogin = (datum) => {
    return {
        type: Constants.LOGIN_OPERATIONS.DO_LOGIN,
        payload: datum
    }
}

export const doLogout = () => {
    return {
        type: Constants.LOGIN_OPERATIONS.DO_LOGOUT,
    }
}