import React from 'react';

function ShimmerElement({width, height, marginTop}) {
    return (
        <div className={`${width} ${height} ${marginTop} animate`}><br/></div>
    );
}

ShimmerElement.defaultProps = {
    marginTop: "",
}
export default ShimmerElement;