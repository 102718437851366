import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import store from "../Library/Store";
import {$} from 'react-jquery-plugin';

const initState = {
    query: "",
    showMenu: false
}

const loginHandler = store.getState().loginHandler;

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = initState;
    }

    doSearch(e, dismissForm = false) {
        e.preventDefault();
        this.props.history.push(`/search/${this.state.query}`)
        if (dismissForm) {
            $("a.btn_search_mob").click();
        }
    }

    componentDidMount() {
        $("a.open_close").on("click", function () {
            $('.main-menu').toggleClass('show');
            $('.layer').toggleClass('layer-is-visible');
        });

        $(".main-menu ul a, #header_menu a:first-child").on("click", function () {
            if ($("a.open_close").css("display") === "block") {
                $('.main-menu').toggleClass('show');
                $('.layer').toggleClass('layer-is-visible');
            }
        });

        $("a.btn_search_mob").on("click", function () {
            $('.search_mob_wp').slideToggle("fast");
            $(".search_mob_wp").find("input[type=text]").focus();
        });

    }

    render() {
        return (
            <>
                <header className="version_1">
                    <div className="layer"/>
                    <div className="main_header">
                        <div className="container">
                            <div className="row small-gutters">
                                <div className="col-xl-3 col-lg-3 d-lg-flex align-items-center">
                                    <div id="logo">
                                        <Link to="/">
                                            <img id="port" src="/assets/images/amp_logo.png" alt="" width="150" height="55"/>
                                            <img id="wide" style={{display: "none"}} src="/assets/images/amp_logo_wide.png" alt=""/>
                                        </Link>
                                    </div>
                                </div>
                                <nav className="col-xl-6 col-lg-7">
                                    <a className="open_close">
                                        <div className="hamburger hamburger--spin">
                                            <div className="hamburger-box">
                                                <div className="hamburger-inner"/>
                                            </div>
                                        </div>
                                    </a>
                                    <div className="main-menu">
                                        <div id="header_menu">
                                            <Link to="/"><img src="/assets/images/amp_logo_wide.png" alt="" width="100" height="35"/></Link>
                                            <a className="open_close" id="close_in"><i className="ti-close"/></a>
                                        </div>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/main/about">About Us</Link></li>
                                            <li><Link to="/products">Products</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                        </ul>
                                    </div>
                                </nav>
                                <div className="col-xl-3 col-lg-2 d-lg-flex align-items-center justify-content-end text-right">
                                    <Link className="phone_top" to={{pathname: "tel://+919829346786"}} target="_top"><strong>
                                        <span>Need Help?</span>+91 98293 46786</strong></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_nav inner Sticky">
                        <div className="container">
                            <div className="row small-gutters">
                                <div className="col-xl-3 col-lg-3 col-md-3"/>
                                <div className="col-xl-6 col-lg-7 col-md-6 d-none d-md-block">
                                    <div className="custom-search-input">
                                        <form onSubmit={(e) => {
                                            this.doSearch(e)
                                        }}>
                                            <input type="text" placeholder="Search books" id="search" value={this.state.query} onChange={() => {
                                                this.setState({query: $("#search").val()})
                                            }} autoComplete="off"/>
                                            <button type="submit"><i className="header-icon_search_custom"/></button>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-2 col-md-3">
                                    <ul className="top_tools">
                                        <li>
                                            <Link to="/cart" className="cart_bt"><strong
                                                className="cart-total">{store.getState().cart.cartTotalItems}</strong></Link>
                                        </li>
                                        <li>
                                            <div className="dropdown dropdown-access">
                                                <Link to="/account" className="access_link"><span>Account</span></Link>
                                                <div className="dropdown-menu">
                                                    {!store.getState().loginHandler.isLoggedIn ?
                                                        <Link to="/account" className="btn_1">Sign In or Sign Up</Link> :
                                                        <>
                                                            <p>Welcome: <strong>{store.getState().loginHandler.userName}</strong></p>
                                                            <ul>
                                                                <li><Link to="/account/orders"><i className="ti-package"/>My Orders</Link></li>
                                                                <li><Link to="/account/me"><i className="ti-user"/>My Profile</Link></li>
                                                                <li><Link to="/account/logout"><i className="ti-lock"/>Logout</Link></li>
                                                            </ul>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a className="btn_search_mob"><span>Search</span></a>
                                        </li>
                                        <li/>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="search_mob_wp">
                            <form onSubmit={(e) => this.doSearch(e, true)}>
                                <input type="text" className="form-control sQuery" id="search" value={this.state.query} onChange={() => {
                                    this.setState({query: $(".sQuery").val()})
                                }} autoComplete="off" placeholder="Search books"/>
                                <button className="btn_1 full-width" type="submit">Search
                                </button>
                            </form>
                        </div>
                    </div>
                </header>
            </>
        );
    }
}

export default withRouter(Header);