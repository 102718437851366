import React, {Component} from 'react';
import {Redirect, withRouter} from "react-router-dom";
import store from "../../Library/Store";
import {doLogout} from "../../Library/Actions/loginActions";

class Logout extends Component {
    componentDidMount() {
        store.dispatch(doLogout())
        this.props.history.push("/")
    }

    render() {
        return (
            <div>
            </div>
        );
    }
};

export default withRouter(Logout);